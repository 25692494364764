    <template>
  <div class="regUser">
    <img
      class="logo"
      src="../assets/web_logo.png"
      alt=""
      width="80px"
      height="80px"
    />

    <van-form @submit="onSubmit">
      <!-- <van-field name="radio" label="用户类型">
        <template #input>
          <van-radio-group v-model="checked" direction="horizontal">
            <van-radio name="3">九小场所</van-radio>
            <van-radio name="1">家庭</van-radio>
            <van-radio name="2">企业</van-radio>
          </van-radio-group>
        </template>
      </van-field> -->
      <van-field
        v-model="username"
        label="用户名"
        clearable
        placeholder="请输入用户名"
        :rules="[{ required: true, message: '' }]"
      />
      <!-- <van-field
        v-model="username"
        label="联系人姓名1"
        clearable
        placeholder="请输入联系人姓名"
        :rules="[{ required: true, message: '' }]"
      /> -->
      <van-field
        v-model="phone"
        label="手机号"
        clearable
        placeholder="请输入手机号"
        :rules="[{ required: true, message: '' }]"
      />
      <van-field
        v-if="needSms"
        v-model="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="[
          { required: true, message: '' },
          { validator: asyncValidator, message: '请输入6位验证码' },
        ]"
      >
        <template #button>
          <van-button
            size="small"
            @click="sendSms"
            type="info"
            :disabled="disabled"
            >{{ disabled ? smsTime + "s后重试" : "发送验证码" }}</van-button
          >
        </template>
      </van-field>
      <van-field
        v-model="fieldValue"
        is-link
        readonly
        label="地区"
        placeholder="请选择所在地区"
        @click="show = true"
      />
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          :field-names="{ text: 'name', value: 'areaCode' }"
          @close="show = false"
          @change="onChange"
        />
      </van-popup>

      <van-field
        v-model="adr"
        center
        clearable
        label="安装地址"
        placeholder="请输入安装地址"
        :rules="[{ required: true, message: '' }]"
      >
        <!-- <template #button>
          <van-button size="small" type="info">点击获取地址</van-button>
        </template> -->
      </van-field>
      <div class="actionB" style="margin: 25px">
        <van-button round block type="info" native-type="submit">
          注册
        </van-button>
      </div>
      <div class="div_reg" @click="$router.push('/login')">
        <span>切换登录</span>
      </div>
    </van-form>
  </div>
</template>
    <script>
export default {
  data() {
    return {
      needSms: false,
      checked: "1",
      username: "",
      nickname: "",
      phone: "",
      sms: "",
      disabled: false,
      smsTime: 60,
      adr: "",
      show: false,
      fieldValue: "",
      cascaderValue: "",
      options: [],
      selectAreaCode: "",
      hasFristLoad: true,
    };
  },
  mounted() {
    this.phone = this.$route.query.phoneNumber;
    this.getAreaList({
      partnerid: "688db879-27e0-11eb-a16a-b8599f49e8c4",
    }).then((res) => {
      this.options = res;
    });
  },
  computed: {},
  methods: {
    onChange({ value, selectedOptions, tabIndex }) {
      this.getAreaList({ parentCode: value, level: tabIndex + 1 }).then(
        (res) => {
          if (res.length) {
            this.treeSet(this.options, value, res);
          } else {
            this.show = false;
            this.selectAreaCode = value;
          }
        }
      );
      this.fieldValue = selectedOptions.map((option) => option.name).join("-");
    },
    treeSet(option, id, children) {
      option.forEach((item) => {
        if (item.areaCode == id) {
          this.$set(item, "children", children);
        } else if (item.children && item.children.length) {
          this.treeSet(item.children, id, children);
        }
      });
    },
    isPoneAvailable(telephone) {
      var myreg = /^1[0-9]{10}$/;
      if (!myreg.test(telephone)) {
        return false;
      } else {
        return true;
      }
    },

    asyncValidator(val) {
      return /\d{6}/.test(val);
    },
    onSubmit() {
      if (this.checked == 0) {
        this.$toast.fail("请选择用户类型");
        return;
      }
      if (!this.isPoneAvailable(this.phone)) {
        this.$toast.fail("请填写正确的手机号码");
        return;
      }
      if (!this.fieldValue) {
        this.$toast.fail("请选择地区");
        return;
      }
      this.http
        .post("/mp/sg/register", {
          companyType: this.checked,
          name: this.username,
          contactName: this.username,
          contactPhone: this.phone,
          installationAddressList: [
            {
              location: this.adr,
              // detail: ,
              areaCode: this.selectAreaCode,
            },
          ],
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$toast.success("注册成功");
            // this.$router.replace({
            //   path: "/login",
            //   query: { phone: this.phone },
            // });
            setTimeout(()=>{
              this.login()
            },1500)
          }
        });
    },
    login(){
      this.http
              .post("/login", {
                username: this.phone,
                password: this.phone.substr(5, 6),
              })
              .then(({ data }) => {
                if (data.status == 0) {
                  this.$toast.success("登陆成功");
                  localStorage.setItem("sessionId", data.result);
                  this.$router.replace("/");
                } else {
                  if (data.message == "用户名未知") {
                    this.$router.push({
                      path: "/regUser",
                      query: { phoneNumber: this.phone },
                    });
                  }
                }
              });
    },
    sendSms() {
      if (this.isPoneAvailable(this.phone)) {
        this.disabled = true;
        let timer = setInterval(() => {
          this.smsTime--;
          if (this.smsTime == 0) {
            this.smsTime = 60;
            this.disabled = false;
            clearInterval(timer);
          }
        }, 1000);
      } else {
        this.$toast.fail("请输入正确的手机号码");
      }
    },
    getAreaList({ level = 0, parentCode = 0 }) {
      let netp = {};
      if (this.hasFristLoad) {
        netp = {
          partnerid: "688db879-27e0-11eb-a16a-b8599f49e8c4",
        };
        this.hasFristLoad = false;
      } else {
        netp = {
          level,
          parentCode,
        };
      }
      return this.http
        .get("/mp/user/gas/searchByCodeAndLevel", {
          params: netp,
        })
        .then((res) => {
          if (
            res.data.code == 200 &&
            res.data.data &&
            res.data.data.length > 0
          ) {
            return res.data.data;
          } else {
            return [];
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.regUser {
  height: 100%;
  width: 100%;
  .logo {
    display: block;
    margin: 10% auto 0;
  }
  .van-form {
    margin-top: 20%;
  }

  .user_area {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .text {
      text-align: center;
      padding: 10px 30px;
      color: #646566;
      font-size: 13px;
      line-height: 24px;
    }
  }
  .user_adr {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    .text {
      text-align: center;
      padding: 10px 30px;
      color: #646566;
      font-size: 13px;
      line-height: 24px;
    }
  }
  .div_reg {
    text-decoration: underline;
    width: 90%;
    text-align: right;
    color: rgb(24, 154, 206);
  }
}
</style>
    